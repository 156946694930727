export const QUESTION_ANSWER_STATUS = {
  UNDO: {
    value: 1,
    label: '未做'
  },
  RIGHT: {
    value: 2,
    label: '正确'
  },
  WRONG: {
    value: 3,
    label: '错误'
  },
  UNREMARK: {
    value: 4,
    label: '未批改'
  },
  REMARKED: {
    value: 5,
    label: '已批改'
  }
}

/**
 * 获取答卷题目状态中文描述，默认"已发布"
 * @param value
 * @returns {*}
 */
export const getStatusByValue = value => {
  const statusList = Object.keys(QUESTION_ANSWER_STATUS)
  for (const status of statusList) {
    if (QUESTION_ANSWER_STATUS[status].value === value) {
      return QUESTION_ANSWER_STATUS[status]
    }
  }
  return QUESTION_ANSWER_STATUS.NEWLY
}
